// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import locale from '../redux/intl/index';
import showCtrl from '../redux/dispalyCtr/index';
import siteList from './siteList';
import login from './login/index';

const store = configureStore({
  reducer: {
    // 将 slice 的 reducer 合并到根 reducer
    locale,
    showCtrl,
    siteList,
    login,
  },
});

export default store;
