import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const Login = lazy(() => import('../pages/Login'));
const Home = lazy(() => import('../pages/Home'));

// 路由提供器组件
export const RoutingProvider = ({ children }) => {
  return (
    <Suspense fallback={<div>loading</div>}>
      <Router>
        <Routes>
          <Route exact path="/home" element={<Home />} />
          <Route path="/login" element={<Login></Login>} />
          <Route path="*" element={<Login></Login>} /> {/* 默认路由 */}
        </Routes>
      </Router>
    </Suspense>
  );
};
