export const getTheme = () => {
  return {
    token: {
      colorPrimary: '#4bff50',
      colorInfo: '#4bff50',
      colorSuccess: '#4bff50',
      colorError: '#fe7272',
      colorWarning: '#ffb13c',
      colorTextBase: '#b7f0fd',
      colorBgBase: '#121a23b3',
      borderRadiusXS: 4,
      borderRadiusSM: 8,
      borderRadiusLG: 8,
      borderRadius: 4,
      wireframe: false,
    },
    components: {
      Button: {
        borderColorDisabled: 'rgba(183,240,253,0.3)',
        dangerColor: 'rgb(255,255,255)',
        defaultActiveBg: 'rgba(75,255,81,0.1)',
        defaultActiveColor: 'rgb(75,255,80)',
        defaultBg: 'rgba(183,240,253,0.1)',
        defaultBorderColor: 'rgba(183,240,253,0.3)',
        defaultColor: 'rgb(183,240,253)',
        defaultGhostBorderColor: 'rgba(183,240,253,0.3)',
        defaultGhostColor: 'rgb(183,240,253)',
        defaultHoverBg: 'rgba(75,255,81,0.1)',
        defaultHoverBorderColor: 'rgb(75,255,80)',
        defaultHoverColor: 'rgb(75,255,81)',
        ghostBg: 'rgba(183,240,253,0.1)',
        primaryColor: 'rgb(69,255,222)',
        colorBorder: 'rgba(183,240,253,0.3)',
        colorError: 'rgb(254,114,114)',
        colorErrorBgActive: 'rgba(229,58,75,0.2)',
        colorErrorHover: 'rgba(254,114,114,0.2)',
        colorPrimaryActive: 'rgba(75,255,81,0.2)',
        colorPrimaryBorder: 'rgb(69,255,222)',
        colorPrimaryHover: 'rgba(75,255,81,0.2)',
        colorTextDisabled: 'rgba(183,240,253,0.4)',
        colorLink: 'rgb(69,255,222)',
        colorPrimary: 'rgba(69,255,221,0.1)',
        colorTextLightSolid: 'rgb(75,255,81)',
        defaultActiveBorderColor: 'rgb(75,255,80)',
        defaultShadow: '0 0px 0 rgba(0, 0, 0, 0.02)',
        dangerShadow: '0 0px 0 rgba(255, 38, 5, 0.06)',
        fontWeight: 400,
        primaryShadow: '0 0px 0 rgba(5, 145, 255, 0.1)',
        colorLinkHover: 'rgb(52,217,63)',
        contentFontSize: 14,
        contentFontSizeLG: 16,
        contentFontSizeSM: 14,
        contentLineHeight: 1.5714285714285716,
        contentLineHeightLG: 1.5,
        contentLineHeightSM: 1.5714285714285716,
        onlyIconSize: 16,
        onlyIconSizeLG: 18,
        paddingBlock: 4,
        paddingBlockLG: 8,
        paddingInline: 16,
        paddingInlineLG: 16,
        paddingInlineSM: 8,
        onlyIconSizeSM: 16,
        controlHeight: 32,
        controlHeightLG: 40,
        controlHeightSM: 24,
        fontSize: 14,
        groupBorderColor: 'rgba(183,240,253,0.3)',
        textHoverBg: 'rgba(0,0,0,0)',
        colorBgTextActive: 'rgba(183,240,253,0)',
        colorErrorBg: 'rgba(255,242,240,0)',
        colorErrorBorderHover: 'rgb(217,87,91)',
      },
      Divider: {
        colorSplit: 'rgba(183,240,253,0.3)',
      },
      Layout: {
        lightSiderBg: 'rgb(69,255,222)',
        headerColor: 'rgb(69,255,222)',
        lightTriggerBg: 'rgb(69,255,222)',
        lightTriggerColor: 'rgb(69,255,222)',
        colorText: 'rgb(183,240,253)',
      },
      Space: {
        paddingXS: 8,
      },
      Anchor: {
        colorSplit: 'rgba(183,240,253,0.3)',
        linkPaddingBlock: 8,
        linkPaddingInlineStart: 16,
      },
      Dropdown: {
        colorBgElevated: 'rgb(18,26,35)',
        colorError: 'rgb(254,114,114)',
        colorTextDisabled: 'rgba(183,240,253,0.5)',
        controlItemBgHover: 'rgba(75,255,81,0.2)',
        colorText: 'rgb(183,240,253)',
        colorSplit: 'rgba(183,240,253,0.3)',
      },
      Typography: {
        colorError: 'rgb(254,114,114)',
        colorErrorActive: 'rgb(217,87,91)',
        colorErrorHover: 'rgb(255,159,156)',
        colorLink: 'rgb(69,255,222)',
        colorLinkHover: 'rgb(75,255,80)',
        colorSuccess: 'rgb(75,255,80)',
        colorText: 'rgb(183,240,253)',
        colorTextDescription: 'rgba(183,240,253,0.6)',
        colorTextDisabled: 'rgba(183,240,253,0.5)',
        colorWarning: 'rgb(255,153,0)',
        colorTextHeading: 'rgb(69,255,222)',
        fontSizeHeading1: 48,
        fontSizeHeading2: 32,
        fontSizeHeading3: 24,
        fontSizeHeading4: 18,
        fontSizeHeading5: 16,
        lineHeight: 1.5714285714285716,
        titleMarginBottom: '0.5em',
        titleMarginTop: '1.2em',
        fontSize: 14,
        lineHeightHeading1: 1.210526315789474,
        lineHeightHeading2: 1.2666666666666666,
        lineHeightHeading3: 1.3333333333333335,
        lineHeightHeading4: 1.4,
        lineHeightHeading5: 1.5,
        marginXS: 8,
        marginXXS: 4,
        paddingSM: 12,
        fontWeightStrong: 600,
      },
      Input: {
        activeBg: 'rgba(75,255,81,0.1)',
        activeBorderColor: 'rgb(75,255,80)',
        addonBg: 'rgba(183,240,253,0.1)',
        hoverBg: 'rgba(75,255,81,0.1)',
        hoverBorderColor: 'rgb(75,255,81)',
        colorBorder: 'rgba(183,240,253,0.3)',
        colorBgContainerDisabled: 'rgba(183,240,253,0.2)',
        colorError: 'rgb(254,114,114)',
        colorErrorBorderHover: 'rgb(254,114,114)',
        colorErrorText: 'rgb(254,114,114)',
        colorSplit: 'rgba(183,240,253,0.3)',
        colorTextDisabled: 'rgba(183,240,253,0.4)',
        colorTextPlaceholder: 'rgba(183,240,253,0.6)',
        colorWarning: 'rgb(255,177,60)',
        colorWarningBorderHover: 'rgb(255,177,60)',
        colorErrorBgHover: 'rgb(254,114,114)',
        warningActiveShadow: '0 0 0 2px rgba(255, 177, 60, 0.3)',
        errorActiveShadow: '0 0 0 2px rgba(254, 114, 115, 0.3)',
        activeShadow: '0 0 0 2px rgba(75, 255, 80, 0.3)',
        colorErrorBg: 'rgb(254,114,114)',
        colorTextDescription: 'rgba(183,240,253,0.6)',
        colorBgContainer: 'rgba(183,240,253,0.1)',
        colorTextQuaternary: 'rgba(183,240,253,0.6)',
      },
      Checkbox: {
        colorBgContainer: 'rgba(183,240,253,0.1)',
        colorBgContainerDisabled: 'rgba(183,240,253,0.4)',
        colorBorder: 'rgb(183,240,253)',
        colorTextDisabled: 'rgba(183,240,253,0.4)',
        colorPrimary: 'rgba(75,255,81,0.1)',
        colorPrimaryHover: 'rgba(75,255,81,0.2)',
        colorWhite: 'rgb(75,255,81)',
        colorPrimaryBorder: 'rgb(75,255,81)',
      },
      Table: {
        bodySortBg: 'rgba(75,255,81,0.1)',
        borderColor: 'rgba(183,240,253,0.3)',
        fixedHeaderSortActiveBg: 'rgb(75,255,80)',
        headerColor: 'rgb(69,255,222)',
        headerFilterHoverBg: 'rgba(75,255,81,0.2)',
        headerSortHoverBg: 'rgba(75,255,81,0.1)',
        headerSortActiveBg: 'rgba(75,255,81,0.1)',
        headerSplitColor: 'rgba(69,255,221,0.3)',
        rowHoverBg: 'rgba(75,255,81,0.1)',
        rowSelectedBg: 'rgba(75,255,81,0.1)',
        rowSelectedHoverBg: 'rgba(75,255,81,0.1)',
        stickyScrollBarBg: 'rgba(183,240,253,0.4)',
        colorLink: 'rgb(69,255,222)',
        colorLinkHover: 'rgb(75,255,80)',
        colorSplit: 'rgba(183,240,253,0.3)',
        colorBgContainer: 'rgba(18,26,35,0)',
      },
      Drawer: {
        colorBgMask: 'rgba(18,26,35,0.7)',
        colorBgTextActive: 'rgba(75,255,81,0.2)',
        colorSplit: 'rgba(183,240,253,0.3)',
        colorPrimaryBorder: 'rgba(183,240,253,0.3)',
        colorIconHover: 'rgb(75,255,81)',
        colorIcon: 'rgb(183,240,253)',
        colorBgElevated: 'rgba(18,26,35,0.7)',
      },
      Select: {
        optionSelectedBg: 'rgba(75,255,81,0.2)',
        colorTextDisabled: 'rgba(183,240,253,0.4)',
        colorTextPlaceholder: 'rgb(183,240,253)',
        colorTextDescription: 'rgba(183,240,253,0.6)',
        selectorBg: 'rgba(183,240,253,0.1)',
        optionSelectedColor: 'rgb(75,255,80)',
        optionActiveBg: 'rgba(183,240,253,0.1)',
        multipleItemBg: 'rgba(183,240,253,0.1)',
        multipleItemBorderColor: 'rgba(183,240,253,0.3)',
        clearBg: 'rgb(183,240,253)',
        colorSplit: 'rgba(183,240,253,0.3)',
        colorBgElevated: 'rgba(18,26,35,0.9)',
        colorBorder: 'rgba(183,240,253,0.3)',
        controlOutline: 'rgba(75,255,81,0.2)',
      },
      Modal: {
        contentBg: 'rgba(18,26,35,0.9)',
        titleColor: 'rgb(69,255,222)',
        colorBgMask: 'rgba(0,0,0,0)',
        colorSplit: 'rgba(183,240,253,0.3)',
      },
      DatePicker: {
        colorBgContainer: 'rgba(183,240,253,0.1)',
        colorTextPlaceholder: 'rgba(183,240,253,0.6)',
        activeShadow: 'none',
        colorBorder: 'rgba(183,240,253,0.3)',
        cellActiveWithRangeBg: 'rgba(75,255,80,0.2)',
        borderRadius: 2,
        borderRadiusSM: 2,
      },
      Tooltip: {
        colorBgSpotlight: 'rgba(18, 26, 35, 1)',
        colorTextLightSolid: 'rgba(183, 240, 253, 1)',
      },
    },
  };
};
