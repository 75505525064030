export const setStorage = (key, value, remember) => {
  sessionStorage.setItem(key, value);
  remember && localStorage.setItem(key, value);
  return;
};

export const getStorage = key => {
  return localStorage.getItem(key) ? localStorage.getItem(key) : sessionStorage.getItem(key);
};

export const removeStorage = key => {
  localStorage.removeItem(key);
  sessionStorage.removeItem(key);
  return;
};

class CacheStore {
  constructor() {
    this.store = {};
  }

  // 设置键值对
  set(key, value) {
    this.store[key] = value;
  }

  // 获取值
  get(key) {
    return Object.prototype.hasOwnProperty.call(this.store, key) ? this.store[key] : null;
  }

  // 删除键值对
  delete(key) {
    if (Object.prototype.hasOwnProperty.call(this.store, key)) {
      delete this.store[key];
      return true;
    }
    return false;
  }

  // 显示所有键值对
  display() {
    for (let key in this.store) {
      if (Object.prototype.hasOwnProperty.call(this.store, key)) {
        console.log(`${key}: ${this.store[key]}`);
      }
    }
  }
}

export const cacheStore = new CacheStore();

export const getCurrentLocation = () => {
  return new Promise((reslove, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          let pos = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          console.log('Latitude is ' + pos.latitude + '°, Longitude is ' + pos.longitude + '°.');
          reslove(pos);
        },
        function (err) {
          console.log('Geolocation get err:', err);
          reject(false);
          // handleLocationError(true);
        },
      );
    } else {
      console.log('Geolocation is not supported by this browser.');
      reject(false);
    }
  });
};

export const truncateString = (str, maxlength) => {
  if (str.length > maxlength) {
    const unit = Math.floor(maxlength / 2);
    const start = str.slice(0, unit);
    const end = str.slice(-unit);
    return start + '...' + end;
  }
  return str;
};
