import { createSlice } from '@reduxjs/toolkit';

export const showCtrlSlice = createSlice({
  name: 'loginSlice',
  initialState: {
    // 初始状态
    showDrawer: false,
    showVersionModal: false,
    showShareModal: false,
    showEventList: false,
  },

  reducers: {
    // 定义 reducer 函数
    setDrawerShow: (state, action) => {
      state.showDrawer = action.payload;
    },
    setVersionModal: (state, action) => {
      state.showVersionModal = action.payload;
    },
    setShareModal: (state, action) => {
      state.showShareModal = action.payload;
    },
    setEventList: (state, action) => {
      state.showEventList = action.payload;
    },
  },
});

export const { setDrawerShow, setVersionModal, setShareModal, setEventList } = showCtrlSlice.actions;

export default showCtrlSlice.reducer;
