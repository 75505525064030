import { createSlice } from '@reduxjs/toolkit';
import { getStorage, removeStorage } from '../../utils/util';

export const loginSlice = createSlice({
  name: 'loginSlice',
  initialState: {
    // 初始状态
    loginName: getStorage('loginName'),
    token: getStorage('token'),
  },
  reducers: {
    // 定义 reducer 函数
    loginSuccss: (state, action) => {
      const { token, username } = action.payload;
      state.loginName = username;
      state.token = token;
    },
    exitLogin: (state, action) => {
      state.loginName = '';
      removeStorage('loginName');
      removeStorage('token');
    },
  },
});

export const { loginSuccss, exitLogin } = loginSlice.actions;

export default loginSlice.reducer;
