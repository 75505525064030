import { createSlice } from '@reduxjs/toolkit';
import { fetchSiteList } from './thunk';

export const siteInfoSlice = createSlice({
  name: 'siteInfoSlice',
  initialState: {
    // 初始状态
    siteList: [],
    requsetStatus: '',
  },

  reducers: {
    // 定义 reducer 函数
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSiteList.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchSiteList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        let newData = action.payload.map(item => {
          return {
            key: item.id,
            SiteName: item.alias || item.id,
            Status: item.online,
            Ownership: item.group,
            Version: item.version,
            lng: item.longitude,
            lat: item.latitude,
            shareNum: item.shared_users_num,
          };
        });
        state.siteList = newData; // 假设我们想要更新状态为获取的数据的 id
      })
      .addCase(fetchSiteList.rejected, (state, action) => {
        state.status = 'failed';
      });
  },
});

export default siteInfoSlice.reducer;
