import enUS from 'antd/es/locale/en_US';
import enMessages from '../messages/en.json';

const EnLang = {
  messages: {
    ...enMessages,
  },
  antd: enUS,
  locale: 'en-US',
};

export default EnLang;
